import React, { useState, Helmet } from 'react';
import { Link, graphql } from 'gatsby';
import Img from 'gatsby-image';

import Banner from '../components/banner';
import Fonts from '../components/fonts';
import Logo from '../images/logo.svg';
import SEO from '../components/seo';
import GithubIcon from '../images/icons/github.svg';
import PhotoIcon from '../images/icons/photo.svg';
import DribbbleIcon from '../images/icons/dribbble.svg';
import ArticleIcon from '../images/icons/news.svg';
import TwitterIcon from '../images/icons/twitter.svg';
import ContactIcon from '../images/icons/contact.svg';

import styles from '../stylesheets/homepage.module.css';

const Homepage = (props) => {
  const { data } = props;
  const { author, title, role } = data.content.siteMetadata;
  const initialTextState = author;
  let timeout;

  const [text, setText] = useState(initialTextState);

  const checkForPointer = () => {
    if (typeof window !== 'undefined') {
      if (matchMedia('(pointer:fine)').matches) {
        return true;
      } else {
        return false;
      }
    }
  };

  const hasMouse = checkForPointer();

  const handlePointerOver = (event) => {
    if (hasMouse) {
      // Cancel timer
      clearTimeout(timeout);
      // if it's a standard <a>
      setText(event.currentTarget.innerText);
    }
  };

  const handlePointerLeave = (event) => {
    if (hasMouse) {
      // Delay by 500ms to avoid text jumping back
      timeout = setTimeout(() => {
        setText(initialTextState);
      }, 500);
    }
  };

  return (
    <div className={styles.homepage}>
      <Banner />
      <Fonts
        families={
          'family=Oswald:wght@700&family=PT+Serif:ital,wght@0,400&family=PT+Sans:wght@400'
        }
      />
      <SEO title={title} />

      <Img
        className={styles.image}
        fixed={data.fileName.childImageSharp.fixed}
      />

      <div className={styles.header}>
        <div className={styles.media}>
          <div className={styles.mediaImage}>
            <div className={styles.logo}>
              <Logo />
            </div>
          </div>
          <div className={styles.mediaBody}>
            <ul className={styles.social}>
              <li className={styles.articlesItem}>
                <Link
                  onPointerEnter={handlePointerOver}
                  onPointerLeave={handlePointerLeave}
                  to="/articles/"
                  className={styles.socialLink}
                >
                  <ArticleIcon />
                  <span>Articles</span>
                </Link>
              </li>
              <li>
                <a
                  onPointerEnter={handlePointerOver}
                  onPointerLeave={handlePointerLeave}
                  href="https://twitter.com/craigmdennis"
                  className={`${styles.socialLink} ${styles.hideText}`}
                >
                  <TwitterIcon />
                  <span>Twitter</span>
                </a>
              </li>
              <li>
                <a
                  onPointerEnter={handlePointerOver}
                  onPointerLeave={handlePointerLeave}
                  href="https://github.com/craigmdennis"
                  className={`${styles.socialLink} ${styles.hideText}`}
                >
                  <GithubIcon />
                  <span>Github</span>
                </a>
              </li>
              <li>
                <a
                  onPointerEnter={handlePointerOver}
                  onPointerLeave={handlePointerLeave}
                  href="https://dribbble.com/craigmdennis"
                  className={`${styles.socialLink} ${styles.hideText}`}
                >
                  <DribbbleIcon />
                  <span>Dribbble</span>
                </a>
              </li>
              <li>
                <a
                  onPointerEnter={handlePointerOver}
                  onPointerLeave={handlePointerLeave}
                  href="https://portfolio.craigmdennis.com"
                  className={`${styles.socialLink} ${styles.hideText}`}
                >
                  <PhotoIcon />
                  <span>Portfolio</span>
                </a>
              </li>
              <li>
                <Link
                  onPointerEnter={handlePointerOver}
                  onPointerLeave={handlePointerLeave}
                  to="/contact/"
                  className={`${styles.socialLink} ${styles.hideText}`}
                >
                  <ContactIcon />
                  <span>Contact Me</span>
                </Link>
              </li>
            </ul>

            <h1 className={styles.title}>{text}</h1>
            <h2 className={styles.description}>{role}</h2>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Homepage;

export const pageQuery = graphql`
  query {
    fileName: file(relativePath: { eq: "background.jpg" }) {
      childImageSharp {
        fixed(width: 2560) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    content: site {
      siteMetadata {
        author
        title
        role
      }
    }
  }
`;
