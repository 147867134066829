import React from 'react';
import styles from '../stylesheets/banner.module.css';

const Banner = ({ text }) => {
  return (
    <div className={styles.container}>
      <p className={styles.text}>
        ✊🏾{' '}
        <a className={styles.link} href="https://blacklivesmatter.com/">
          <span>#blacklivesmatter</span>
        </a>{' '}
        – Educate yourself about systemic racism and how to help black people in
        your community.
      </p>
    </div>
  );
};

export default Banner;
